import { RouteConfig } from "src/config/routes.config";

type InitialConfigType = Omit<RouteConfig, "component">;

export const REDIRECT_PAGE: InitialConfigType = {
  path: "/redirect",
  auth: false,
};

export const SETUP_PAGE: InitialConfigType = {
  path: "/gemcam-setup",
  auth: false,
};

export const SETUP_HOME_PAGE: InitialConfigType = {
  path: "/gemcam-setup/home",
  auth: false,
};

export const SETUP_DOWNLOAD_PAGE: InitialConfigType = {
  path: "/gemcam-setup/download",
  auth: false,
};

export const SHOPIFY_PRICING_PAGE: InitialConfigType = {
  path: "/shopify/pricing",
  auth: false,
  showNavigation: true,
};

export const SHOPIFY_CONNECT_PAGE: InitialConfigType = {
  path: "/shopify",
  auth: false,
};

export const SUBACCOUNT_INVITE_FAILED_PAGE: InitialConfigType = {
  path: "/subaccount-invite-failed",
  auth: false,
};

export const SUBACCOUNT_LOGIN_FAILED_PAGE: InitialConfigType = {
  path: "/subaccount-login-failed",
  auth: false,
};

export const CREATE_PAGE: InitialConfigType = {
  path: "/create",
  auth: true,
  showNavigation: true,
};

export const PRODUCTS_PAGE: InitialConfigType = {
  path: "/products",
  auth: true,
  showNavigation: true,
};

export const PRODUCTS_IMPORT_PAGE: InitialConfigType = {
  path: "/products/import",
  auth: true,
};

export const PRODUCT_CREATE_PAGE: InitialConfigType = {
  path: "/product/create",
  auth: true,
  showNavigation: true,
};

export const PRODUCT_PAGE: InitialConfigType = {
  path: "/product/:productId",
  auth: true,
  showNavigation: true,
};

export const PRODUCT_EDIT_PAGE: InitialConfigType = {
  path: "/product/:productId/edit",
  auth: true,
  showNavigation: true,
};

export const CAMERA_PAGE: InitialConfigType = {
  path: "/camera",
  auth: true,
};

export const CAMERA_PAGE_TEST: InitialConfigType = {
  path: "/camera-test",
  auth: true,
};

export const MEDIA_PAGE: InitialConfigType = {
  path: "/media",
  auth: true,
  showNavigation: true,
};

export const EDIT_MEDIA_PAGE: InitialConfigType = {
  path: "/media/:mediaId/edit",
  auth: true,
};

export const AR_MEDIA_PAGE: InitialConfigType = {
  path: "/media/ar",
  auth: true,
};

export const CATALOG_PAGE: InitialConfigType = {
  path: "/catalog",
  auth: true,
  showNavigation: true,
};

export const CATALOG_DETAILS_PAGE: InitialConfigType = {
  path: "/catalog/:catalogId",
  auth: true,
  showNavigation: true,
};

export const CREATE_MANUAL_CATALOG_PAGE: InitialConfigType = {
  path: "/catalog/create/manual",
  auth: true,
  showNavigation: true,
};

export const CREATE_AUTO_CATALOG_PAGE: InitialConfigType = {
  path: "/catalog/create/auto",
  auth: true,
  showNavigation: true,
};

export const ADD_PRODUCT_TO_CATALOG_PAGE: InitialConfigType = {
  path: "/catalog/:catalogId/add-product",
  auth: true,
  showNavigation: true,
};

export const TRASH_BIN_PAGE: InitialConfigType = {
  path: "/trash-bin",
  auth: true,
  showNavigation: true,
};

export const AUTH_PAGE: InitialConfigType = {
  path: "/auth",
  auth: false,
};

export const LOGIN_PAGE: InitialConfigType = {
  path: "/auth/login",
  auth: false,
};

export const SIGNUP_PAGE: InitialConfigType = {
  path: "/auth/signup",
  auth: false,
};

export const CONFIRM_EMAIL_PAGE: InitialConfigType = {
  path: "/confirm-email",
  auth: false,
};

export const SETTINGS_PAGE: InitialConfigType = {
  path: "/settings",
  auth: true,
  showNavigation: true,
};

export const LOGOUT_PAGE: InitialConfigType = {
  path: "/logout",
  auth: false,
};

export const QR_CODE_PAGE: InitialConfigType = {
  path: "/scan",
  auth: false,
};

export const ACTIVATE_PAGE: InitialConfigType = {
  path: "/activate",
  auth: true,
};

export const LIFETIME_ACTIVATE_PAGE: InitialConfigType = {
  path: "/lifetime-activate",
  auth: true,
  authRedirect: true,
};

// Integration Pages ↓
export const RAPNET_PAGE: InitialConfigType = {
  path: "/integration/rapnet",
  showNavigation: true,
  auth: true,
};

export const PAYMENTS_PAGE: InitialConfigType = {
  path: "/integration/payments",
  showNavigation: false,
  auth: true,
};

export const ORDER_PAGE: InitialConfigType = {
  path: "/integration/payments/:orderId",
  showNavigation: false,
  auth: true,
};

export const LINKEDIN_CALLBACK_PAGE: InitialConfigType = {
  path: "/auth/linkedin/callback",
  showNavigation: false,
  auth: true,
};

export const MAINTENANCE_PAGE: InitialConfigType = {
  path: "/maintenance",
  auth: false,
};

export const WECHAT_PAGE: InitialConfigType = {
  path: "/wechat",
  showNavigation: false,
  auth: false,
};

// Integration Pages ↑

export const DEFAULT_PAGE = { path: "/", auth: false };

export const NOT_FOUND_PAGE = { path: "*", auth: false };
